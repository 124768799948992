import { useTipLinkContext } from '../context/TipLinkContext'
import { formatBalance } from '../util'

const Expired = ({ value, onClick }) => {
  const { user, memo } = useTipLinkContext()
  return (
    <div className="expired-container card">
      <div>
        <div className="expired-amount">{formatBalance(value, 'PHP')}</div>
        <div className="lightning-qr-memo expired-memo">{memo}</div>
      </div>
      <div className="expired-text-container">
        <div className="expired-text-title">Oops!</div>
        <div className="expired-text-subtitle">
          This Lightning Invoice Has Expired.
        </div>
      </div>
      <div className="lightning-qr-user-details">
        <div className="lightning-qr-username">@{user.username}</div>
        <div className="lightning-qr-tip-greeting">{user.tipGreeting}</div>
      </div>
      <button className="btn" onClick={onClick}>
        Get A New One
      </button>
    </div>
  )
}

export default Expired
