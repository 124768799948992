import { useState } from 'react'

export const useLightning = () => {
  const [loading, setLoading] = useState(false)

  const createBolt = async (data) => {
    try {
      setLoading(true)
      const rawResponse = await fetch('/api/v0/transaction/create-bolt11', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      const responseJSON = await rawResponse.json()
      return responseJSON
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const checkBolt = async (bolt11) => {
    try {
      setLoading(true)

      const rawResponse = await fetch(`/api/v0/transaction/check-bolt11?bolt11=${bolt11}`)
      const responseJSON = rawResponse.json()
      return responseJSON
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return { loading, createBolt, checkBolt }
}
