import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { io } from 'socket.io-client'

import { useLightning } from '../hooks/useLightning'
import { useUser } from '../hooks/useUser'
import { parseBolt11 } from '../util'

export const TipLinkContext = createContext()

export const TipLinkProvider = ({ children }) => {
  const username = window.location.pathname.toLowerCase().split('/')[1]
  const searchParams = new URLSearchParams(window.location.search)
  const { user, loading: loadingUser } = useUser(username)
  const [showLightningQr, setShowLightningQr] = useState(false)
  const { checkBolt, createBolt, loading: loadingLightning } = useLightning()
  const width = window.innerWidth
  const height = window.innerHeight
  const qrCodeSize = width >= 340 ? 260 : width * 0.9
  const qrCodeLogoSize = width >= 340 ? 60 : (width / 60) * 10
  const expirationRef = useRef(null)
  const [connected, setConnected] = useState(false)
  const [paid, setPaid] = useState(false)
  const [expiry, setExpiry] = useState(0)
  const [expiresInSeconds, setExpiresInSeconds] = useState(expiry)
  const [timeExpireDate, setTimeExpireDate] = useState(0)
  const [transactionData, setTransactionData] = useState(null)
  const spAmount = searchParams.get('amount') || ''
  const spCurrency = searchParams.get('currency') || 'PHP'
  const spMemo = searchParams.get('memo') || ''
  const [checkingInvoice, setCheckingInvoice] = useState(false)
  const [amount, setAmount] = useState(spAmount)
  const [memo, setMemo] = useState(spMemo)

  useEffect(() => {
    if (timeExpireDate) {
      expirationRef.current = setInterval(() => {
        const expires =
          timeExpireDate - Math.floor(new Date().getTime() / 1000) - 1
        setExpiresInSeconds(expires)
        if (expires <= 0) {
          clearInterval(expirationRef.current)
        }
      }, 1000)
    }
  }, [timeExpireDate])

  const connectToSocket = (tx) => {
    const socket = io('/', { autoConnect: false })
    socket.connect()
    socket.emit('subscribe_to_tx_id', tx._id)
    socket.on('connect', () => {
      setConnected(true)
    })
    socket.on('connect_error', () => {
      setConnected(false)
    })
    socket.on('disconnect', () => {
      setConnected(false)
    })
    socket.on('data', (data) => {
      data.status === 'completed' && setPaid(true)
      data.metadata?.redirectUrl &&
        window.location.replace(data.metadata?.redirectUrl)
    })
  }

  const onShowQrCode = async () => {
    const data = {
      amount: Number(amount),
      username: user.username,
      memo: memo,
      currency: spCurrency
    }

    console.log('user', user)

    if (user.username === 'dragonpay') {
      if (searchParams.get("refno")) data.refno = searchParams.get("refno")
      if (searchParams.get("ccy")) data.currency = searchParams.get("ccy")
      if (searchParams.get("description")) data.description = searchParams.get("description")
      if (searchParams.get("billerId")) data.billerId = searchParams.get("billerId")
      if (searchParams.get("email")) data.email = searchParams.get("email")
      if (searchParams.get("digest")) data.digest = searchParams.get("digest")
      if (searchParams.get("merchantid")) data.merchantid = searchParams.get("merchantid")
      if (searchParams.get("amount")) data.dpAmountForDigest = searchParams.get("amount")
    }

    console.log('data', data)

    const response = await createBolt(data)

    setTransactionData(response)
    const { timestamp, timeExpireDate } = parseBolt11(response.bolt11)
    const expiry = timeExpireDate - timestamp - 1

    setExpiry(expiry)
    setExpiresInSeconds(expiry)
    setTimeExpireDate(timeExpireDate)
    connectToSocket(response)
    setShowLightningQr(true)
  }

  const startOver = () => {
    setAmount('')
    setMemo('')
    setPaid(false)
    setTransactionData(null)
    setExpiry(0)
    setExpiresInSeconds(0)
    setTimeExpireDate(0)
    clearInterval(expirationRef.current)
    setShowLightningQr(false)
  }

  const onCheckInvoice = async () => {
    if (checkingInvoice) return
    setCheckingInvoice(true)
    await checkBolt(transactionData.bolt11)
    setCheckingInvoice(false)
  }

  const onChangeAmount = (event) => {
    setAmount(event.target.value?.trim())
  }

  const onChangeMemo = (event) => {
    setMemo(event.target.value)
  }

  return (
    <TipLinkContext.Provider
      value={{
        user,
        amount,
        memo,
        spCurrency,
        spAmount,
        spMemo,
        showLightningQr,
        expiresInSeconds,
        expiry,
        width,
        height,
        transactionData,
        paid,
        qrCodeSize,
        qrCodeLogoSize,
        connected,
        checkingInvoice,
        onShowQrCode,
        startOver,
        onCheckInvoice,
        onChangeAmount,
        onChangeMemo,
        loadingUser,
        loadingLightning
      }}
    >
      {children}
    </TipLinkContext.Provider>
  )
}

export const useTipLinkContext = () => useContext(TipLinkContext)
