import Confetti from 'react-confetti'
import { useTipLinkContext } from '../context/TipLinkContext'
import { formatBalance } from '../util'

const Success = ({ value, onClick }) => {
  const { width, height, user, transactionData, memo } = useTipLinkContext()

  return (
    <>
      <Confetti
        width={width}
        height={height}
        recycle={false}
        colors={['#6922FF', '#FF6B6B', '#FFFF55', '#1CD787', '#F7931A']}
      />
      <div className="success-container card">
        <div>
          <div className="success-amount">{formatBalance(value, 'PHP')}</div>
          <div className="lightning-qr-memo success-memo">{memo}</div>
        </div>
        <div className="checkmark">✓</div>
        <div className="lightning-qr-user-details">
          <div className="lightning-qr-username">@{user.username}</div>
          <div className="lightning-qr-tip-greeting">{user.tipGreeting}</div>
        </div>
        <div className="success-transaction-id-container">
          <div className="success-transaction-id-title">Transaction Id</div>
          <div className="success-transaction-id-subtitle">
            {transactionData._id}
          </div>
        </div>
        <button className="btn" onClick={onClick}>
          Home
        </button>
      </div>
    </>
  )
}

export default Success
