import React, { useState } from 'react'

import IconLightning from './components/IconLightning'
import IconBitcoin from './components/IconBitcoin'
import { useTipLinkContext } from './context/TipLinkContext'
import BackButton from './components/BackButton'
import Success from './components/Success'
import Expired from './components/Expired'
import LightningQr from './components/LightningQr'
import LightningInvoice from './components/LightningInvoice'
import BitcoinQr from './components/BitcoinQR'

const App = () => {
  const {
    user,
    amount,
    showLightningQr,
    expiresInSeconds,
    paid,
    onShowQrCode,
    startOver,
    loadingUser,
    loadingLightning
  } = useTipLinkContext()
  const [tab, setTab] = useState('lightning')

  if (loadingUser || loadingLightning) {
    return (
      <div className="profile">
        <div className="loader" />
      </div>
    )
  }

  // Redirect to sign up page if user does not exist
  if (!user && !loadingUser) {
    window.location.href = 'https://app.pouch.ph/signup'
    return null
  }

  return (
    <div>
      {showLightningQr && <BackButton onClick={startOver} />}
      {showLightningQr ? (
        paid ? (
          <Success value={amount} onClick={startOver} />
        ) : expiresInSeconds <= 0 ? (
          <Expired value={amount} onClick={onShowQrCode} />
        ) : (
          <LightningQr />
        )
      ) : (
        <LightningInvoice />
      )}
    </div>
  )

  // return (
  //   <div>
  //     {showLightningQr ? (
  //       <BackButton onClick={startOver} />
  //     ) : (
  //       <div className='tabs'>
  //         <button className='tab' onClick={() => setTab('lightning')}>
  //           <IconLightning />
  //         </button>
  //         <button className='tab' onClick={() => setTab('bitcoin-address')}>
  //           <IconBitcoin />
  //         </button>
  //       </div>
  //     )}

  //     {tab === 'lightning' ? (
  //       showLightningQr ? (
  //         paid ? (
  //           <Success value={amount} onClick={startOver} />
  //         ) : expiresInSeconds <= 0 ? (
  //           <Expired value={amount} onClick={onShowQrCode} />
  //         ) : (
  //           <LightningQr />
  //         )
  //       ) : (
  //         <LightningInvoice />
  //       )
  //     ) : (
  //       <BitcoinQr />
  //     )}
  //   </div>
  // )
}

export default App
