import { QRCodeSVG } from 'qrcode.react'

import { useTipLinkContext } from '../context/TipLinkContext'
import { useProfilePicture } from '../hooks/useProfilePicture'
import { copyToClipboard, formatBalance } from '../util'

const LightningQr = () => {
  const {
    user,
    amount,
    memo,
    expiresInSeconds,
    expiry,
    transactionData,
    qrCodeSize,
    connected,
    checkingInvoice,
    onCheckInvoice
  } = useTipLinkContext()
  const { url } = useProfilePicture({
    username: user.username,
    userId: user._id
  })

  return (
    <div className="lightning-qr-container">
      <div className="progress-bar">
        <div
          className="progress-bar-inner"
          style={{ width: `${(expiresInSeconds / expiry) * 100}%` }}
        />
      </div>
      <div className="lightning-qr-content">
        <div
          className="connection-indicator-container"
          onClick={onCheckInvoice}
        >
          <div>{checkingInvoice ? 'Refreshing' : 'Refresh'}</div>
          <div
            className="connection-indicator"
            style={{ backgroundColor: connected ? '#1CD787' : '#FF6B6B' }}
          />
        </div>
        <div className="lightning-qr-amount">
          {formatBalance(amount, 'PHP')}
        </div>
        <div className="lightning-qr-memo">{memo}</div>
        <div className="qr-container">
          <img
            className="qr-user-profile"
            src={url}
            alt="qr-user-profile"
            width={64}
            height={64}
          />
          <QRCodeSVG value={transactionData.bolt11} size={qrCodeSize} />
        </div>
        <div className="lightning-qr-user-details">
          <div className="lightning-qr-username">@{user.username}</div>
          <div className="lightning-qr-tip-greeting">{user.tipGreeting}</div>
        </div>
        <button
          className="btn"
          onClick={() => copyToClipboard(transactionData.bolt11)}
        >
          Copy
        </button>
      </div>
    </div>
  )
}

export default LightningQr
