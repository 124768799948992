import { useEffect, useState } from 'react'

export const useUser = (username) => {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true)
        const rawResponse = await fetch(`/api/v0/user?username=${username}`)
        const responseJSON = await rawResponse.json()
        setUser(responseJSON.user)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchUser()
  }, [username])

  return { user, loading }
}
