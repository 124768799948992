import bolt11 from 'bolt11'

const commaNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const toFixedFloored = (num = 0, places) => {
  const splitStr = num.toString().split('.')
  const beforeDecimalPoint = splitStr[0]
  const afterDecimalPoint = splitStr[1] || ''
  let decimalResult = ''
  for (let i = 0; i < places; i++) {
    decimalResult += afterDecimalPoint[i] || '0'
  }
  return places > 0 ? beforeDecimalPoint + '.' + decimalResult : beforeDecimalPoint
}

export const formatBalance = (amount, currency) => {
  if (currency === 'BTC') return commaNumber(toFixedFloored(Number(amount), 0)) + ' SAT'
  if (currency === 'PHP') return '₱' + commaNumber(toFixedFloored(Number(amount), 2))
  if (['CAD', 'USD'].includes(currency)) return '$' + commaNumber(toFixedFloored(Number(amount), 2))
  return 'invalid currency'
}

export const parseBolt11 = (str) => {
  let parsedBolt11
  try {
    parsedBolt11 = bolt11.decode(str?.replace(/lightning:|LIGHTNING:/g, ''))
    return parsedBolt11
  } catch {
    return null
  }
}

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
}
