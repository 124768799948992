import axios from 'axios'
import { useEffect, useState } from 'react'

export const useProfilePicture = ({ username, userId }) => {
  const [url, setUrl] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchProfilePicture()
  }, [])

  const fetchProfilePicture = async () => {
    try {
      setLoading(true)

      const { data } = await axios.get(
        `/api/v2/user/profile-picture?username=${username}&userId=${userId}`
      )
      setUrl(data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    url,
    loading
  }
}
