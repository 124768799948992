const BackButton = ({ onClick }) => {
  return (
    <button className='back-button' onClick={onClick}>
      <div className='back-button-arrow'>←</div>
      <div className='back-button-text'>Back</div>
    </button>
  )
}

export default BackButton
