import { useTipLinkContext } from '../context/TipLinkContext'
import { useProfilePicture } from '../hooks/useProfilePicture'

const LightningInvoice = () => {
  const {
    user,
    amount,
    memo,
    onShowQrCode,
    onChangeAmount,
    onChangeMemo,
    spCurrency,
    spAmount
  } = useTipLinkContext()
  const { url, loading } = useProfilePicture({
    username: user.username,
    userId: user._id
  })

  if (loading) return null

  if (spAmount) {
    onShowQrCode()
    return null
  }

  return (
    <div className="lightning-container card">
      <div className="lightning-header">
        <img
          id="profile-pic"
          src={url}
          alt="profile-pic"
          width={48}
          height={48}
        />
        <div id="username">{user.username}</div>
        <div id="tip-greeting">{user.tipGreeting}</div>
      </div>
      <div className="lightning-input-fields">
        <div className="amount-container">
          <i>{spCurrency}</i>
          <input
            id="amount"
            type="number"
            autoFocus
            value={amount}
            onChange={onChangeAmount}
          />
        </div>
        <input
          id="memo"
          type="text"
          placeholder="Leave a note..."
          value={memo}
          onChange={onChangeMemo}
        />
      </div>
      <button
        disabled={!Boolean(amount)}
        className="btn"
        id="show-qr-code"
        onClick={() => onShowQrCode()}
      >
        Show QR Code
      </button>
    </div>
  )
}

export default LightningInvoice
